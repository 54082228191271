.title-subtitle {
  @apply flex flex-col items-center w-100%;

  &__title {
    @apply font-inter font-semibold text-body-lg whitespace-nowrap overflow-hidden text-ellipsis;
  }

  &__subtitle {
    @apply font-inter font-normal text-caption-lg whitespace-nowrap overflow-hidden text-ellipsis w-100%;
    @apply flex justify-center;
  }
}
