.reservation-page {
  $base: &;
  @apply pb-100px;
  @apply lg:pb-20px;

  &__header-subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__left-col-content,
  &__right-col-content {
    @apply lg:max-w-450px lg:pb-72px;
  }

  &__left-col-content {
    @apply hidden lg:block;

    .breadcrumbs {
      @apply mb-24px;
    }
  }

  &__right-col-content {
    @apply ml-auto;

    .breadcrumbs {
      @apply hidden lg:flex;
    }
  }

  &__edit-button {
    @apply font-inter font-medium text-2xs text-on-tertiary-container;
    @apply flex items-center cursor-pointer;

    &-icon {
      @apply scale-75 fill-on-tertiary-container;
    }
  }

  &__location-preview {
    @apply overflow-hidden relative;
    @apply lg:rounded-t-16px;

    &-data {
      @apply absolute bottom-0 left-0 right-0 hidden justify-between;
      @apply pt-20% px-16px pb-16px;
      @apply lg:flex;
      background: linear-gradient(
        180deg,
        rgba(17, 23, 32, 0) 0%,
        rgba(17, 23, 32, 0.7) 100%
      );
    }

    &-left {
      @apply flex flex-col;
    }

    &-name {
      @apply font-optima font-semibold text-xs text-on-secondary;
    }

    &-address {
      @apply font-inter font-normal text-body-sm text-on-secondary;
    }
  }

  &__order {
    @apply mx-8px border border-surface-variant bg-surface+3 rounded-16px -mt-53px relative;
    @apply lg:rounded-0 lg:rounded-b-16px lg:mt-0 lg:mx-0;

    &-header {
      @apply h-52px border-b border-surface-variant pl-16px flex items-center justify-between;

      #{$base}__edit-button {
        @apply mr-16px;
      }
    }

    &-body {
      @apply mx-16px;

      &-title {
        @apply h-52px flex items-center justify-between;
      }

      &-item {
        @apply py-16px border-b border-surface-variant;

        &:first-child {
          @apply pt-0;
        }

        &:last-child {
          @apply border-b-0;
        }
      }
    }

    &-footer {
      @apply h-88px border-t border-surface-variant pl-64px pt-12px flex flex-col;

      &-price {
        @apply flex justify-between pr-16px;
        @apply font-inter text-body-sm text-on-surface;
        &-total {
          @apply flex justify-between pr-16px;
          @apply font-inter font-semibold text-body-sm text-on-surface;
        }
      }
    }
  }

  &__details-footer {
    @apply mx-16px mt-16px font-inter text-caption-lg text-on-surface-variant;

    &-link {
      @apply text-tertiary cursor-pointer;
    }
  }
}

.order-controls {
  @apply flex px-16px font-inter text-secondary font-normal;
  @apply border-t border-surface-variant pt-7px pb-34px;
  @apply fixed bottom-0 left-0 right-0 z-10 bg-background;
  @apply lg:hidden;

  & > div {
    @apply flex-1;
  }

  .controls-data {
    @apply flex flex-col;

    &-title {
      @apply text-body-sm;
    }

    &-value {
      @apply text-md font-medium;
    }
  }

  .controls-button {
    .button {
      @apply w-100%;
    }
  }
}
