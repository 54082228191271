$input-types: text, email, password, tel, number;

@mixin input-type($type: "", $extra-class: "") {
  @if $type != "" {
    input[type="#{$type}"]#{$extra-class} {
      @content;
    }
  } @else {
    $selectors: ();
    @each $input-type in $input-types {
      $selectors: append(
        $selectors,
        unquote("input[type=#{$input-type}]#{$extra-class}"),
        comma
      );
    }
    #{$selectors} {
      @content;
    }
  }
}

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: #{$lines};
  line-clamp: #{$lines};
  -webkit-box-orient: vertical;
}
