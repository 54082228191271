.avatar {
  $base: &;
  @apply font-semibold font-inter;
  @apply bg-surface;
  @apply overflow-hidden;
  @apply flex items-center justify-center;

  &__image {
    @apply w-100% h-100%;
  }

  &--circle {
    @apply rounded-1/2 animate-appear;
  }

  &--square {
    @apply rounded animate-appear;
    #{$base}--xxs {
      @apply rounded-4px;
    }

    #{$base}--xs {
      @apply rounded-4px;
    }

    #{$base}--sm {
      @apply rounded-6px;
    }

    #{$base}--md {
      @apply rounded-6px;
    }

    #{$base}--lg {
      @apply rounded-8px;
    }

    #{$base}--xl {
      @apply rounded-8px;
    }
  }

  &--xxs {
    @apply w-24px h-24px min-w-24px min-h-24px;
    @apply text-caption-sm;
  }

  &--xs {
    @apply w-32px h-32px min-w-32px min-h-32px;
    @apply text-caption-lg;
  }

  &--sm {
    @apply w-40px h-40px min-w-40px min-h-40px;
    @apply text-body-sm;
  }

  &--md {
    @apply w-48px h-48px min-w-48px min-h-48px;
    @apply text-body-lg;
  }

  &--lg {
    @apply w-56px h-56px min-w-56px min-h-56px;
    @apply text-body-lg;
  }

  &--xl {
    @apply w-64px h-64px min-w-64px min-h-64px;
    @apply text-body-lg;
  }
}
