.image-element {
  @apply relative;

  &--loading {
    &:after {
      content: "";
      @apply absolute top-0 left-0 right-0 bottom-0;
      background-image: repeating-linear-gradient(
        237deg,
        rgba(48, 47, 46, 0) 0%,
        rgba(48, 47, 46, 0.05) 10%,
        rgba(48, 47, 46, 0) 20%
      );
      background-size: 400%;
      background-position: 0 100%;
      animation: diagonal-gradient 6s ease infinite;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  img {
    @apply h-100% w-100%;
    @apply object-cover;
  }
}

@keyframes diagonal-gradient {
  50% {
    background-position: 100% 0;
  }
}
