.badge {
  @apply font-inter font-semibold text-caption-sm whitespace-nowrap;
  @apply inline-flex items-center justify-center;
  @apply rounded-1/2;
  @apply w-6px;
  @apply h-6px;

  &--sm {
    @apply w-18px;
    @apply h-18px;
  }

  &--lg {
    @apply w-auto h-auto;
    @apply rounded-100px;
    @apply px-8px;
    @apply py-1px;
  }

  &--default {
    @apply bg-background;
  }

  &--filled--primary {
    @apply bg-primary;
    @apply text-on-primary;
  }

  &--filled--secondary {
    @apply bg-secondary;
    @apply text-on-secondary;
  }

  &--filled--tertiary {
    @apply bg-tertiary;
    @apply text-on-tertiary;
  }

  &--filled--error {
    @apply bg-error;
    @apply text-on-error;
  }

  &--filled--success {
    @apply bg-success;
    @apply text-on-success;
  }

  &--outlined--primary {
    @apply bg-transparent;
    @apply text-primary;
    @apply border border-primary;
  }

  &--outlined--secondary {
    @apply bg-transparent;
    @apply text-secondary;
    @apply border border-secondary;
  }

  &--outlined--tertiary {
    @apply bg-transparent;
    @apply text-tertiary;
    @apply border border-tertiary;
  }

  &--outlined--error {
    @apply bg-transparent;
    @apply text-error;
    @apply border border-error;
  }

  &--outlined--success {
    @apply bg-transparent;
    @apply text-success;
    @apply border border-success;
  }
}
