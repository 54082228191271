.order-item {
  @apply flex flex-col;

  &__content {
    @apply flex gap-16px;
  }

  &__left-col {
    @apply w-32px;
    @apply flex flex-col items-center;
  }

  &__dashed-line {
    @apply h-100% w-1px;
    @apply border-l border-dashed border-l-on-surface-variant;
  }

  &__dot {
    @apply relative top-12px h-1px;
  }

  &__artist-avatar {
    @apply z-10;

    &--first {
      @apply -mt-8px;
    }
  }

  &__right-col {
    @apply flex-2;

    &-content {
      @apply font-inter font-normal;
      @apply flex flex-col py-6px;

      & > div {
        @apply flex flex-col;
      }

      svg {
        @apply fill-on-surface-variant mx-9px;
      }
    }
  }

  &__name {
    @apply font-inter font-semibold text-body-sm text-on-surface;
  }

  &__time {
    @apply font-inter text-body-sm text-on-surface-variant;
    @apply flex flex-row;
  }

  &__services {
    @apply mt-6px mb-16px;

    &--last {
      @apply mb-0;
    }
  }

  &__service {
    @apply flex justify-between;
    @apply font-inter text-body-sm text-on-surface;
  }
}
