.notifications-section {
  @apply lg:grow;

  &__title {
    @apply px-16px mt-24px mb-8px;
    @apply font-inter text-caption-lg text-on-surface-variant;
    @apply lg:px-0;
  }

  &__contacts {
    @apply bg-surface+3;
    @apply lg:rounded-8px;
    @apply lg:border lg:border-surface-variant;
  }

  &__contact {
    @apply mx-16px;
    @apply flex items-center;

    &-value {
      @apply font-inter font-semibold text-body-sm text-on-surface;
    }

    &:last-child {
      @apply border-0;
    }
  }
}
