.desktop-modal {
  @apply flex justify-center items-center;
  @apply overflow-y-hidden;

  &--active {
    @apply fixed top-0 bottom-0 left-0 right-0;
    @apply z-99999 box-border;
    background: rgba(7, 9, 13, 0.6);
  }

  &__container {
    @apply w-450px;
    @apply max-h-[calc(100vh_-_160px)] box-border;
    @apply overflow-y-scroll;
    @apply rounded-16px;
  }

  &__header {
    @apply flex justify-between items-center;
    @apply h-64px;
    @apply relative;
    @apply border-b border-b-surface-variant;
    @apply bg-background;
    @apply px-8px;
    @apply z-10 sticky top-0;
  }

  &__title {
    @apply absolute left-1/2 -translate-x-50% box-border;
    @apply font-inter font-semibold text-body-lg text-on-surface;
  }

  &__content-wrapper {
    @apply flex flex-col h-100%;
  }

  &__content {
    @apply h-100%;
    @apply bg-background;
    @apply overflow-hidden;
  }
}
