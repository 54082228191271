.two-cols-layout {
  @apply min-h-100vh;
  @apply lg:flex lg:justify-center lg:px-24px lg:py-16px;

  &--gap {
    @apply lg:gap-24px;
    @apply xl:gap-32px;
  }

  @media (min-width: 992px) {
    min-height: calc(100vh - 16px);
  }

  &__left-col {
    @apply w-100%;

    &--default {
      @apply overflow-hidden;
    }
  }

  &__right-col {
    @apply w-100%;

    &--default {
      @apply lg:max-w-375px;
    }
  }

  &__separator {
    @apply hidden px-5px items-center;
    @apply lg:flex lg:flex-col;

    &-line {
      @apply w-1px;
      @apply flex-1;
      @apply bg-surface-variant;
      @apply -my-3px;
    }

    svg {
      @apply fill-surface-variant;

      &:first-child {
        @apply rotate-180;
      }
    }
  }
}
