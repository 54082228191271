.homepage {
  @apply box-border;
  min-height: calc(100vh - 65px);
  background-color: #f9f4f4;
  @apply flex flex-col items-center;
  @apply relative;

  @media (min-width: 992px) {
    min-height: calc(100vh - 72px);
  }

  &:after {
    content: "";
    display: block;
    height: 50px;
  }

  &:not(&__image) {
    @apply z-10;
  }

  &__image {
    @apply absolute top-[138px] sm:top-16;
    @apply w-375px h-487px box-border;
    @apply lg:w-486px lg:h-630px box-border;
    @apply xl:w-550px xl:h-714px box-border;
  }

  &__title {
    @apply box-border;
    @apply text-3xs uppercase font-inter text-primary font-semibold;
    @apply lg:hidden;
  }

  &__caption {
    @apply mt-16px;
    @apply lg:mt-256px box-border;
    @apply xl:mt-297px box-border;
    @apply text-xl font-optima text-secondary font-medium text-center -tracking-0002em box-border;
    @apply w-295px box-border;
    @apply lg:w-424px box-border;
    @apply lg:text-2xl lg:-tracking-0005em box-border;
  }

  &__companies-list {
    @apply p-12px;
    @apply mt-24px;
    @apply w-350px box-border;
    @apply lg:p-0;
    @apply lg:w-100%;
    @apply relative mt-80px lg:mt-0;
  }

  &__mobile-location {
    @apply mb-16px;
    @apply lg:hidden;
  }

  .swiper-slide {
    width: auto;
  }

  .company-card {
    @apply mb-16px;
    @apply lg:hidden;
  }

  &__show-more-button {
    @apply w-100%;
    @apply flex justify-center;
    @apply lg:hidden;
  }

  .companies-navigation {
    @apply justify-center;
    @apply text-primary;
    @apply font-inter font-semibold text-button-sm text-primary uppercase tracking-005em box-border;
    @apply gap-4px;
    @apply mt-8px;
  }

  .companies-slider {
    @apply hidden lg:block;
  }

  .right-navigation-button,
  .left-navigation-button {
    .state-layer {
      @apply bg-transparent	#{!important};

      .content {
        @apply opacity-100 #{!important};

        svg {
          @apply fill-primary #{!important};
        }
      }
    }

    &.swiper-button-disabled {
      @apply opacity-45 #{!important};
    }
  }
}
