.services-component {
  @apply bg-surface+3 rounded-t-16px relative flex flex-col;
  @apply lg:overflow-hidden lg:shadow-evaluation-2 lg:rounded-16px;

  &__active-persons-counter {
    @apply font-inter font-medium text-xs text-on-tertiary-container mx-11px;
  }

  &__pagination-title {
    @apply font-inter font-semibold text-button-lg text-on-tertiary-container m-8px;
  }

  &__header {
    @apply p-12px border-b border-b-outline-variant flex justify-between;

    &-left-side {
      @apply flex items-center;
    }

    &-right-side {
      @apply flex items-center;
    }
  }

  &__body {
    @apply mb-73px flex-1 flex flex-col;
    @apply overflow-y-scroll overflow-x-hidden overscroll-none;

    &-swiper {
      overflow: unset;
      @apply flex-1 w-100% p-8px;
      &-slide {
        @apply h-100% rounded-8px;
        &-content {
          @apply border border-surface h-100% rounded-8px relative;
        }
      }

      &-navigation {
        &-left-button {
          @apply absolute top-[calc(50%_-_20px)] left-12px;
          @apply z-10;
          @apply bg-surface+4 #{!important};
          @apply shadow-evaluation-2 #{!important};
          @apply flex;
        }

        &-right-button {
          @apply absolute top-[calc(50%_-_20px)] right-12px;
          @apply z-10;
          @apply bg-surface+4 #{!important};
          @apply shadow-evaluation-2 #{!important};
          @apply flex;
        }

        &-disabled-button {
          @apply hidden #{!important};
        }
      }

      &-custom-pagination {
        @apply flex;

        &-icon-active {
          @apply hidden;
        }

        &-bullet {
          @apply cursor-pointer;

          svg {
            @apply fill-on-tertiary-container pointer-events-none;
          }

          &-active {
            @apply cursor-default relative;

            .services-component__body-swiper-custom-pagination-icon-active {
              @apply flex;
            }

            .services-component__body-swiper-custom-pagination-icon {
              @apply hidden;
            }

            svg {
              @apply fill-on-tertiary-container;
            }

            &::after {
              content: "";
              @apply absolute -bottom-5px left-3px right-3px h-2px rounded-2px bg-on-tertiary-container;
            }
          }
        }
      }
    }
  }

  &__footer {
    @apply bg-background border-t border-t-outline-variant p-10px gap-10px;
    @apply fixed bottom-0 left-0 right-0 flex justify-center z-10;
    @apply sm:bg-transparent sm:border-0;
    @apply lg:absolute lg:bg-surface+3 lg:border-t;

    &-info {
      @apply flex items-center;
      @apply gap-1px;
      @apply border-2 border-primary;
      @apply rounded-8px;
      @apply px-13px;
      @apply font-inter font-medium text-button-lg text-primary;
      @apply sm:bg-surface+3 lg:bg-transparent;

      svg {
        @apply -mx-3px;
        @apply fill-primary scale-75;
      }
    }
  }

  &__services-block {
    @apply px-24px pb-24px pt-1px h-100% bg-surface+3;
    @apply lg:px-16px rounded-lg;

    &-header {
      @apply flex items-center justify-between mt-16px -mb-8px;
    }

    &-person-title {
      @apply font-inter font-semibold text-caption-sm text-on-background;
    }

    &-copy-btn {
      @apply flex items-center cursor-pointer;
      @apply font-inter font-semibold text-caption-sm text-on-background;
      @apply bg-surface rounded-20px pl-8px pr-12px;

      svg {
        @apply fill-on-background scale-75;
      }

      &:hover {
        @apply bg-surface+1;
      }
    }

    &-title {
      @apply py-24px border-b border-on-background;
    }
  }
}
