.card {
  @apply border rounded-8px border-surface-variant;
  @apply bg-surface+3;
  @apply h-140px w-326px lg:w-354px box-border;
  @apply inline-flex;
  @apply overflow-hidden;
  @apply cursor-pointer;
  @apply transition-shadow;
  @apply animate-appear;

  &:hover {
    @apply shadow-evaluation-2;
  }

  &:active {
    @apply shadow-transparent;
    @apply bg-surface-sl-low;
  }

  &__left-side {
    @apply min-w-100px box-border;
  }

  &__right-side {
    @apply flex flex-col justify-between;
    @apply p-16px;
    @apply text-on-surface;
    @apply overflow-hidden;
  }

  &__card-image {
    @apply h-100% w-100%;
    @apply object-cover;
  }

  &__title {
    @apply font-optima font-semibold text-xs text-on-surface;
    @apply lg:text-sm;
    @apply truncate;
  }

  &__info {
    @apply flex items-center gap-2px;
    @apply text-caption-lg font-inter font-semibold;
  }

  &__group-icon {
    @apply scale-75;
  }

  &__rating-element {
    @apply flex items-center mr-3px;
    svg {
      @apply mr-3px -ml-3px;
      @apply fill-primary scale-75;
    }
  }

  &__address {
    @apply text-caption-lg font-inter font-normal text-on-surface-variant;
    @apply truncate;
  }

  &__time-slots {
    @apply flex;
  }

  &__time-slot {
    @apply mr-4px;
    @apply text-caption-sm lg:text-caption-lg;
    &:last-child {
      @apply mr-0;
    }
  }
}
