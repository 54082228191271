.list-item-component {
  @apply py-16px;
  @apply border-b border-b-outline-variant;
  @apply flex;
  @apply transition-colors;

  &:hover {
    @apply cursor-pointer;
    @apply border-b-on-surface-variant;
  }

  &--active {
    @apply cursor-pointer;
    @apply bg-surface-sl-low;
    @apply border-b-on-surface-variant;
  }

  &__left-side {
    @apply flex-1;
  }

  &__icon {
    @apply ml-10px;
  }

  &__content {
    @apply flex flex-col;
    &-title {
      @apply font-inter font-semibold text-body-sm text-on-surface;
    }
    &-description {
      @apply font-inter font-normal text-caption-lg text-on-surface-variant;
    }
  }
}
