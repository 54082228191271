.user {
  @apply flex flex-col items-center gap-16px;
  @apply mt-48px lg:mb-16px;

  &__info {
    @apply text-center;

    &-name {
      @apply font-optima font-semibold text-on-surface text-md;
      @apply lg:font-medium lg:text-lg lg:tracking-[-0.002em] box-border;
    }

    &-phone {
      @apply font-inter text-on-surface-variant text-body-lg;
    }
  }
}
