.photo-slider {
  @apply flex flex-row;
  @apply overflow-x-scroll;
  @apply overflow-y-hidden;
  @apply overscroll-contain;

  .photo {
    @apply object-cover;
    @apply min-w-100% min-h-100%;
  }
}
