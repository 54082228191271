.select {
  &__error-msg-container {
    @apply pt-4px pb-10px pl-16px min-h-32px;
    @apply font-inter font-normal text-caption-lg text-error;
  }

  &__wrapper {
    background-color: white;
    @apply flex items-center;
    @apply border border-outline-variant rounded-8px;
    @apply h-56px;
    @apply relative;
    @apply cursor-pointer;

    &:hover {
      background: rgba(48, 47, 46, 0.05);
    }
  }

  &__left-side {
    @apply flex flex-col;
    @apply w-100%;
    @apply pl-16px py-8px;
  }

  &__label {
    @apply font-inter text-caption-lg text-on-surface;
  }

  &__selected {
    @apply font-inter text-body-lg text-on-surface;
    @apply p-0 m-0;
    @apply h-24px;
  }

  &__options {
    @apply absolute top-54px -left-1px;
    width: calc(100% + 2px);
    @apply border border-outline-variant rounded-8px;
    background-color: white;
    @apply z-20;
  }

  &__option {
    @apply pl-16px py-8px;

    &:hover {
      background: rgba(48, 47, 46, 0.05);
    }
  }

  &__right-side {
    @apply flex items-center;
    @apply mx-12px;
    @apply cursor-pointer;
  }

  &__input-wrapper {
    @apply -mt-1px;
  }

  &--open {
    .select__wrapper {
      @apply rounded-b-0;
      .select__options {
        @apply rounded-t-0;
      }
    }
  }

  &--error {
    .select__wrapper {
      @apply border-error;
    }
  }
}
