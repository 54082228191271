@import "mixins";

.input {
  $base: &;
  $input: #{$base}__input-element;
  @apply relative;

  @include input-type($extra-class: #{$input}) {
    @apply font-inter font-normal text-body-lg text-on-surface;
    @apply h-56px p-1px pl-16px;
    @apply border-outline-variant border rounded-8px;
    @apply placeholder-on-surface;
    @apply w-100%;
    @apply transition-colors;
    @apply bg-surface+3;

    &:disabled {
      @apply bg-surface+3;
      @apply cursor-not-allowed;
      @apply opacity-40;
    }

    &::placeholder {
      @apply text-on-surface-variant;
    }

    &:hover:enabled {
      @apply bg-surface-sl-low;
    }

    &:focus:enabled {
      @apply border-2 border-primary;
      @apply text-on-surface;
      @apply outline-none;
      @apply p-0 pl-15px shadow-none;
    }
  }

  &__icon-container {
    @apply absolute top-0 left-0 h-56px flex items-center px-12px;
  }

  &__icon {
    @apply fill-on-surface-variant;
  }

  &__label-container {
    @apply absolute top-0 left-16px bottom-0 pt-16px;
    @apply pointer-events-none;
    @apply transition-all;
  }

  &__label {
    @apply font-inter font-normal;
    @apply pointer-events-none;
    @apply text-on-surface;
    @apply transition-all;
  }

  &__msg-container {
    @apply pt-4px pb-10px pl-16px min-h-32px;
    @apply font-inter font-normal text-caption-lg;
  }

  &__error-msg {
    @apply text-error;
  }

  &__hint-msg {
    @apply block text-on-surface-variant;
  }

  &__button-container {
    @apply absolute right-0 top-0 h-56px flex items-center px-5px;
  }

  &--icon-visible {
    @include input-type($extra-class: #{$input}) {
      @apply pl-47px;

      &:focus:enabled {
        @apply pl-46px;
      }
    }

    #{$base}__label-container {
      @apply left-47px;
    }
  }

  &--label-visible {
    @include input-type($extra-class: #{$input}) {
      @apply pt-20px;

      &:focus:enabled {
        @apply pt-19px;
        box-shadow: none;
      }
    }
  }

  &--clear-button-visible {
    @include input-type($extra-class: #{$input}) {
      @apply pr-50px;

      &:focus:enabled {
        @apply pr-50px;
      }
    }
  }

  &--error {
    @include input-type($extra-class: #{$input}) {
      @apply border-error;
    }

    #{$base}__msg-container {
      @apply opacity-100;
    }
  }

  &--small-label {
    #{$base}__label-container {
      @apply pt-4px;
    }
    #{$base}__label {
      @apply text-caption-lg;
    }
  }
}
