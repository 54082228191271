.review-modal {
  &__content {
    @apply flex flex-col items-center;
    @apply my-24px px-16px;
    @apply w-100%;
    @apply font-inter text-body-lg text-on-surface;
  }

  &__name {
    @apply font-semibold;
  }

  &__info {
    @apply flex gap-2px items-center;

    svg {
      @apply fill-primary;
    }
  }

  &__text {
    @apply mt-16px;
    @apply text-on-surface-variant;
  }
}
