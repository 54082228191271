.login-form {
  &__description {
    @apply font-inter text-body-lg text-on-surface-variant;
    @apply mt-8px;
  }

  &__form {
    @apply mt-24px;

    &-controls {
      @apply flex gap-16px;
    }
  }

  .divider {
    @apply border-outline-variant;
    @apply mt-32px;
    @apply mb-16px;
  }

  .forgot-password {
    @apply font-inter text-body-sm text-on-surface-variant;
    @apply flex items-center cursor-pointer;

    &-icon {
      @apply scale-75;
    }
  }
}
