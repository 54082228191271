.change-artist-container {
  @apply p-16px;
  @apply h-100%;
  @apply overflow-y-scroll;
  @apply flex flex-col gap-8px;

  .person-wrapper {
    @apply mt-24px;

    .artist-wrapper {
      @apply flex justify-between items-center;

      .left-side {
        @apply flex items-center;
        @apply gap-16px;
        @apply h-72px;

        .artist-info {
          @apply flex flex-col;

          .artist-name {
            @apply font-inter font-semibold text-body-sm text-on-surface;
          }

          .artist-link {
            @apply font-inter text-caption-lg text-on-surface-variant;
          }
        }
      }
    }

    hr {
      @apply border-t border-outline-variant;
    }

    svg {
      @apply fill-primary;
    }
  }
}
.change-artist-buttons-wrapper {
  @apply w-100%;
  @apply bg-background;
  @apply lg:rounded-b-16px;
  @apply lg:w-375px box-border;

  .person-wrapper {
    @apply h-56px;
    @apply border-t border-t-surface-variant;
    @apply px-16px;
  }

  .apply-button-wrapper {
    @apply px-16px py-8px;
    @apply border-t border-t-surface-variant;
  }
}

.person {
  @apply flex items-center;
  @apply gap-12px;
  @apply ml-4px mb-8px;
  @apply font-inter font-semibold text-3xs text-primary uppercase;
}

.select-artist-item {
  @apply flex flex-col;
  @apply border border-surface-variant rounded-16px;
  @apply bg-surface+3 px-16px pt-16px;

  &-title {
    @apply font-inter font-semibold text-3xs text-on-background;
  }

  &-selector {
    @apply border-t border-surface-variant;

    &:first-child {
      @apply border-t-0;
    }
  }
}
