.credits-section {
  @apply lg:grow;

  &__title {
    @apply px-16px mb-8px;
    @apply font-inter text-caption-lg text-on-surface-variant;
    @apply lg:px-0;
  }

  &__credits-list {
    @apply flex flex-col;
    @apply bg-surface+3;
    @apply lg:border lg:border-surface-variant;
    @apply lg:rounded-8px;
  }

  &__credit-cont {
    @apply flex flex-col relative;
    @apply px-16px py-8px;
  }

  &__credit {
    @apply grid grid-cols-[48px,auto,100px] gap-x-4 items-center;
  }

  &__company-logo {
    @apply w-48px h-48px flex items-center rounded-[6px] justify-center;
  }

  &__credit-company {
    @apply flex flex-col gap-[2px] box-border;
  }

  &__company-name {
    @apply font-inter text-body-sm text-on-surface-variant;
  }

  &__company-amount {
    @apply font-inter text-caption-lg text-on-surface-variant;
  }

  &__divider {
    @apply absolute bottom-0 left-4 right-4 h-px bg-surface-variant;
  }
}
