.button {
  @apply font-medium font-inter;
  @apply rounded;
  @apply overflow-hidden;
  @apply flex items-stretch;
  @apply transition-colors;
  -webkit-tap-highlight-color: transparent;

  .state-layer {
    @apply flex;
    .content {
      @apply flex items-center;
      svg {
        @apply w-24px h-24px;
      }
      .icon-left {
        @apply -ml-8px mr-3px;
      }
      .icon-right {
        @apply ml-3px -mr-8px;
      }
      .submitting {
        animation: rotation 2s infinite linear;
      }
    }
  }

  &.flexible {
    .state-layer {
      @apply w-100% h-100% justify-center;
    }
  }

  &.lg:not(.flexible) {
    .state-layer {
      @apply px-28px;
    }
  }

  &.lg {
    @apply h-52px;
    @apply text-button-lg;
  }

  &.sm:not(.flexible) {
    .state-layer {
      @apply px-24px;
      .content {
        svg {
          @apply scale-75;
        }
      }
    }
  }

  &.sm {
    @apply h-40px;
    @apply text-button-sm;
  }

  &.outlined {
    &:not(:disabled) {
      &:hover {
        @apply border-2;
        &.lg:not(.flexible) {
          .state-layer {
            @apply px-27px;
          }
        }
        &.sm:not(.flexible) {
          .state-layer {
            @apply px-23px;
          }
        }
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
