.breadcrumbs {
  @apply flex;
  @apply items-center;
  @apply text-body-sm;
  @apply font-inter;
  @apply h-40px;

  &__root {
    @apply font-semibold;
    @apply cursor-pointer;
  }

  &__path {
    @apply fill-on-surface-variant scale-75;
  }

  &__link {
    @apply font-normal;
    @apply text-on-surface-variant;
  }

  &__links {
    @apply flex;
    @apply items-center;
    @apply gap-2px;
  }
}
