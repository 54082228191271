.sign-up-form {
  &__description {
    @apply font-inter text-body-lg text-on-surface-variant;
    @apply mt-8px;
  }

  &__pronoun-select {
    &--advanced {
      .select__wrapper {
        @apply rounded-b-0;
      }
      .select__error-msg-container {
        @apply hidden;
      }
    }
  }

  &__other-input {
    @apply -mt-1px;
    input {
      @apply rounded-t-0 #{!important};
    }
    &--hidden {
      @apply hidden;
    }
  }

  &__form {
    @apply mt-24px;
    &-controls {
      @apply flex gap-16px;
    }
  }
}
