.icon-button {
  @apply flex items-stretch;
  @apply rounded-1/2;
  @apply overflow-hidden;
  @apply transition-colors;
  -webkit-tap-highlight-color: transparent;

  &.sm {
    @apply w-40px h-40px;
  }

  &.lg {
    @apply w-52px h-52px;
  }

  .state-layer {
    @apply flex-1 flex items-center justify-center;
  }
}
