.suggestion-chip {
  @apply font-inter font-semibold text-2xs whitespace-nowrap;
  @apply inline-flex items-center justify-center;
  @apply rounded-6px;
  @apply h-32px min-h-26px min-w-59px px-7px;

  &--low {
    @apply bg-secondary-container;
    @apply text-on-surface;
  }

  &--low-alternative {
    @apply bg-surface;
    @apply text-on-surface;
  }

  &--high {
    @apply bg-secondary;
    @apply text-secondary-container;
  }

  &--high-alternative {
    @apply bg-on-surface;
    @apply text-surface;
  }

  &--primary {
    @apply bg-primary;
    @apply text-on-primary;
  }
}
