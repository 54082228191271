.location-preview {
  $base: &;
  @apply rounded-16px;
  @apply overflow-hidden;
  @apply relative;
  @apply transition-shadow;

  &__content {
    @apply absolute top-0 left-0 right-0 bottom-0;
  }

  &__image {
    @apply w-100% h-100%;

    img {
      object-position: top;
    }

    &:before {
      content: "";
      background: linear-gradient(
        180deg,
        rgba(17, 23, 32, 0) 0%,
        rgba(17, 23, 32, 0.7) 100%
      );
      @apply block absolute top-0 w-100% h-100% z-10;
    }
  }

  &__info {
    @apply w-100% min-h-80px;
    @apply absolute bottom-0 z-10;
    @apply flex items-center justify-between;
    @apply p-16px;
  }

  &__logo {
    @apply mr-8px;
  }

  &__main-info {
    @apply overflow-hidden;
    @apply flex flex-2 flex-col;
    @apply text-on-secondary;
    @apply font-optima text-xs sm:font-inter sm:text-body-sm font-semibold sm:font-normal;

    span {
      @apply whitespace-nowrap overflow-hidden text-ellipsis;
    }
  }

  html:lang(vi) & {
    #{$base}__main-info {
      @apply font-inter;
    }
  }

  &__rating {
    @apply ml-18px;
    @apply text-body-sm;
    @apply self-end items-center;
    @apply flex whitespace-nowrap;
    @apply text-on-secondary;

    svg {
      @apply mr-3px mb-4px scale-75;
      @apply fill-on-secondary;
    }
  }

  &:hover {
    @apply cursor-pointer;
    box-shadow: 0px 6px 10px 4px rgba(48, 47, 46, 0.15);
    filter: drop-shadow(0px 2px 3px rgba(48, 47, 46, 0.3));
  }

  &:active {
    box-shadow: none;
    filter: none;
  }
}
