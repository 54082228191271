.payment-methods-section {
  $base: &;
  @apply lg:grow;

  &__title {
    @apply px-16px mb-8px;
    @apply font-inter text-caption-lg text-on-surface-variant;
    @apply lg:px-0;
  }

  &__payment-methods-list {
    @apply flex flex-col;
    @apply bg-surface+3;
    @apply lg:border lg:border-surface-variant;
    @apply lg:rounded-8px;
  }

  &__cards-wrapper {
    @apply mt-24px mb-16px;
    @apply lg:mt-0;
  }

  &__add-button {
    @apply w-100%;
    @apply px-16px;
    @apply lg:px-0;

    &--hidden {
      @apply lg:hidden;
    }
  }

  &__add-card-controls {
    @apply flex gap-8px;

    &-left {
      @apply shrink-0;
    }
  }
  &__error-msg-container {
    @apply pt-4px pb-10px pl-16px min-h-32px;
    @apply font-inter font-normal text-caption-lg text-error;
  }

  &--error {
    @apply border-error;

    #{$base}__error-msg-container {
      @apply opacity-100;
    }
  }
  .StripeElement {
    padding: 20px 12px;
    border: solid 1px #ccc7c0;
    border-radius: 8px;
  }
  .StripeElement--focus {
    border: solid 1px #b8722a;
  }
}
