.booking-confirmation-modal {
  &__wrapper {
    @apply p-16px lg:px-48px;
    @apply flex flex-col items-center justify-center gap-16px;
    @apply h-100%;
  }

  &__checkmark {
    @apply border-2 border-success rounded-1/2;
    @apply h-56px w-56px;
    @apply flex justify-center items-center;

    &-icon {
      @apply fill-success;
      @apply scale-150;
    }
  }

  &__text {
    @apply font-optima font-medium text-xl text-success;
  }

  &__bottom-controls {
    @apply bg-background;
    @apply p-16px lg:px-48px lg:pb-48px;

    &-buttons {
      @apply flex flex-col lg:flex-row gap-16px;
    }
  }

  .desktop-modal__header {
    @apply border-b-0;
  }
}
