.add-to-calendar-modal {
  &__container {
    @apply p-16px pb-24px lg:py-32px;
    @apply flex justify-center;
  }

  &__buttons {
    @apply max-w-300px;
    @apply flex flex-col items-center;
  }
}
