.pointment-details {
  @apply px-16px;

  &__order {
    @apply flex gap-8px;
    @apply py-16px;
    @apply border-b border-b-surface-variant;

    &-left-side {
      @apply flex;

      &-avatar {
        @apply border-2 border-background;

        &:first-child {
          @apply z-10;
        }

        &:last-child {
          @apply -ml-8px;
        }
      }
    }

    &-right-side {
      @apply flex flex-col gap-16px;
      @apply w-100%;

      &-top {
        @apply flex items-center gap-4px;
      }

      &-caption {
        @apply font-inter text-caption-lg text-on-surface-variant;
      }

      &-name {
        @apply font-inter text-body-sm text-on-surface;
      }

      svg {
        @apply fill-on-surface-variant;
      }

      &-services {
        @apply font-inter text-body-sm text-on-surface;
      }
    }
  }

  &__price-row {
    @apply flex items-center justify-between;
    @apply font-inter text-body-sm text-on-surface;
  }

  &__summary {
    @apply ml-80px pt-16px pb-8px;
  }

  &__payment {
    @apply flex gap-16px;

    &-icon-wrapper {
      @apply shrink-0;
    }

    &-method {
      @apply font-inter font-semibold text-on-surface text-body-sm;
    }

    &-caption {
      @apply font-inter text-on-surface-variant text-caption-lg;
    }
  }
}
