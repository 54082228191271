.input-chip {
  @apply overflow-hidden;
  @apply flex items-stretch;
  @apply border border-outline rounded-30px;
  @apply font-inter text-2xs font-medium text-on-background;
  @apply h-32px;
  @apply transition-colors;

  &:not(.rounded-chip) {
    .state-layer {
      @apply px-12px;
      .icon-left {
        @apply -ml-3px mr-2px;
      }
      .icon-right {
        @apply ml-2px -mr-3px;
      }
    }
  }

  .state-layer {
    @apply flex items-center;
    svg {
      @apply fill-on-background;
    }
  }

  &.rounded-chip {
    @apply rounded-1/2;
    @apply w-32px;
    .state-layer {
      @apply flex-1 justify-center;
    }
  }

  &.selected {
    &.primary {
      @apply border-primary;
      @apply text-on-primary;
      @apply bg-primary;
      .state-layer {
        svg {
          @apply fill-on-primary;
        }
      }

      &:hover {
        .state-layer {
          @apply bg-primary-sl-low;
        }
      }
      &:active {
        .state-layer {
          @apply bg-primary-sl-hight;
        }
      }
    }

    &.secondary {
      @apply border-0;
      @apply text-on-secondary;
      @apply bg-secondary;
      .state-layer {
        svg {
          @apply fill-on-secondary;
        }
      }

      &:hover {
        .state-layer {
          @apply bg-secondary-sl-low;
        }
      }
      &:active {
        .state-layer {
          @apply bg-secondary-sl-hight;
        }
      }
    }

    &.tertiary {
      @apply border-0;
      @apply text-on-tertiary;
      @apply bg-tertiary;
      .state-layer {
        svg {
          @apply fill-on-tertiary;
        }
      }

      &:hover {
        .state-layer {
          @apply bg-tertiary-sl-low;
        }
      }
      &:active {
        .state-layer {
          @apply bg-tertiary-sl-hight;
        }
      }
    }

    &.error {
      @apply border-0;
      @apply text-on-error;
      @apply bg-error;
      .state-layer {
        svg {
          @apply fill-on-error;
        }
      }

      &:hover {
        .state-layer {
          @apply bg-error-sl-low;
        }
      }
      &:active {
        .state-layer {
          @apply bg-error-sl-hight;
        }
      }
    }

    &.success {
      @apply border-0;
      @apply text-on-success;
      @apply bg-success;
      .state-layer {
        svg {
          @apply fill-on-success;
        }
      }

      &:hover {
        .state-layer {
          @apply bg-success-sl-low;
        }
      }
      &:active {
        .state-layer {
          @apply bg-success-sl-hight;
        }
      }
    }
  }

  &:hover:not(.selected) {
    .state-layer {
      @apply bg-background-sl-low;
    }
  }

  &:active:not(.selected) {
    .state-layer {
      @apply bg-background-sl-hight;
    }
  }
}
