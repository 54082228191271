.privacy-policy {
  p,
  h3,
  h5 {
    color: #302f2e !important;
  }

  @apply flex flex-col gap-10 px-4 py-10 text-on-background text-body-sm;

  &__last-updated {
    @apply text-primary text-caption-lg font-semibold flex gap-1 items-center;

    svg {
      @apply fill-primary;
    }
  }

  &__contents {
    @apply flex flex-col gap-10;
  }

  &__content {
    @apply flex flex-col gap-4;
  }

  &__list {
    @apply flex flex-col gap-1;
  }

  &__list-item {
    @apply text-body-sm text-on-background relative pl-6;

    &::before {
      @apply absolute top-3 left-2 w-[4px] h-[4px] rounded-full bg-on-background transform -translate-y-1/2;
      content: "";
    }
  }

  &__warning-text {
    @apply text-primary font-normal;
  }

  &__link {
    @apply font-semibold;
  }
}
