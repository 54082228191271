.edit-account-section {
  @apply lg:grow;
  @apply pb-48px;

  &__wrapper {
    @apply px-16px;
    @apply lg:px-0;
  }

  &__photo {
    @apply flex items-center gap-16px;
    @apply my-24px;
  }

  &__pronoun-select {
    &--advanced {
      .select__wrapper {
        @apply rounded-b-0;
      }
      .select__error-msg-container {
        @apply hidden;
      }
    }
  }

  &__other-input {
    @apply -mt-1px;
    input {
      @apply rounded-t-0 #{!important};
    }
    &--hidden {
      @apply hidden;
    }
  }

  &__button-wrapper {
    @apply fixed bottom-0;
    @apply bg-background;
    @apply w-100%;
    @apply px-16px py-8px;
    @apply border-t border-surface-variant;
    @apply lg:hidden;
  }
}
