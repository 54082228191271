.radio {
  .state-layer {
    .radio__container {
      @apply border-2 rounded-1/2;
      @apply w-20px h-20px;
    }
  }

  &.disabled {
    &.checked {
      .radio__container {
        @apply bg-transparent;
      }
    }
  }

  &:not(.disabled) {
    &.primary,
    &.secondary,
    &.tertiary,
    &.error,
    &.success {
      &.checked {
        .state-layer {
          .radio__container {
            @apply bg-transparent;
          }
        }
      }
    }
  }
}
