.date-and-time-select-container {
  @apply w-100% h-100%;
  @apply flex flex-col flex-grow-3 items-center;
  @apply relative;

  .date-and-time-select-wrapper {
    @apply flex flex-grow-4 justify-center;
    @apply gap-16px;
    @apply h-100% w-100%;
    @apply relative;
    @apply z-10;
    @apply overflow-y-hidden;

    .left-side,
    .right-side {
      @apply absolute top-1/2;
      @apply flex flex-col;
      @apply w-47% h-100%;
      //@apply overflow-y-hidden;
    }

    .left-side {
      @apply items-end;
      @apply left-0;
    }

    .right-side {
      @apply right-0;
    }

    .item {
      @apply font-inter text-body-lg text-on-surface-variant;
      @apply p-8px;
      @apply z-20;
      @apply cursor-pointer;
      @apply select-none;
      @apply relative;
    }

    .loadingTransparentText {
      color: transparent !important;
      transition: color 300ms ease-in-out;
    }

    .loadingSkeleton {
      opacity: 0;
      width: 90px;
      height: 24px;
      position: absolute;
      top: 8px;
      left: 8px;
      border-radius: 12px;
      transition: opacity 300ms ease-in-out;
    }
    .loadingActive {
      opacity: 1;
    }

    .active {
      @apply font-semibold text-primary;
    }

    .unavailable {
      @apply line-through;
    }
  }

  .select-line {
    @apply absolute top-1/2;
    @apply bg-primary-container;
    @apply w-100% h-40px;
    @apply z-0;
  }

  .top-fade-out,
  .bottom-fade-out {
    @apply absolute;
    @apply bg-surface+2;
    @apply z-30;
    @apply w-100%;
    @apply h-80px;
    @apply pointer-events-none;
  }

  .top-fade-out {
    @apply top-0;
    mask-image: linear-gradient(to bottom, black 0%, transparent 100%);
  }

  .bottom-fade-out {
    @apply bottom-0;
    mask-image: linear-gradient(to top, black 0%, transparent 100%);
  }
}
