.service-detail-container {
  @apply bg-surface+2;
  @apply h-100%;
  @apply overflow-y-scroll overscroll-none;
  @apply relative;
  @apply pb-72px lg:pb-0;

  .photo-slider-container {
    @apply relative;
    background: rgba(7, 9, 13, 0.6);

    .photo-slider-wrapper {
      @apply min-h-240px max-h-240px box-border;
      @apply rounded-t-16px;
    }

    .close-button {
      @apply absolute top-16px left-16px;
      @apply bg-background #{!important};
    }

    .counter-badge {
      @apply absolute bottom-16px right-16px;
      background-color: rgba(7, 9, 13, 0.5);
    }

    .formatted-name-badge {
      @apply absolute bottom-16px left-16px;
    }
  }

  .service-detail-wrapper {
    @apply p-16px;
  }

  .info {
    @apply ml-2px;

    .price-duration {
      @apply font-inter font-semibold text-body-sm text-on-surface;
    }
  }

  .title {
    @apply font-semibold text-md text-on-surface;
  }

  .description {
    @apply mt-8px;
    @apply font-inter text-body-sm text-on-surface-variant;
  }

  hr {
    @apply my-16px;
    @apply border border-surface-sl-low;
  }

  .special-conditions {
    @apply flex;
    @apply gap-8px;
    @apply font-inter text-body-sm text-on-surface;
  }

  .add-on {
    @apply flex justify-start items-start;
    .add-on-title {
      @apply font-inter font-semibold text-body-sm text-on-surface;
    }

    .add-on-info {
      @apply font-inter text-caption-lg text-on-surface;
    }
    .list-item-component__icon {
      @apply transition-transform duration-200;
    }
  }

  .rotateChildIcon {
    @apply flex-wrap;
    .list-item-component__icon {
      @apply rotate-180 transition-transform duration-200;
    }
  }

  .rituals {
    @apply mt-24px;
  }
}

.add-button-wrapper {
  @apply fixed bottom-0 flex justify-center items-center;
  @apply bg-surface+2;
  @apply border-t border-t-surface-sl-low;
  @apply p-16px;
  @apply w-100%;
  @apply lg:static;

  .add-buttons {
    @apply w-100% max-w-[480px] flex gap-2;
    .add-button {
      @apply w-100%;
    }
  }
}
