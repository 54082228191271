.company-page {
  @apply -mt-64px lg:mt-0;

  &__breadcrumbs-wrapper {
    @apply hidden;
    @apply lg:block;
  }

  &__info {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply lg:items-start;

    &-media {
      @apply w-100%;
      @apply flex;
      @apply flex-col;
      @apply items-center;
      @apply lg:relative;

      &-cover {
        @apply w-100%;
        @apply overflow-hidden;
        @apply lg:rounded-16px;
      }

      .avatar {
        margin-top: -28px;
        @apply border-2;
        @apply z-10;
        @apply border-solid;
        @apply border-surface+2;
        @apply lg:m-0;
        @apply lg:border-on-background-sl-hight;
        @apply lg:bg-clip-padding;
        @apply lg:absolute;
        @apply lg:top-16px;
        @apply lg:left-16px;
      }
    }

    &-name {
      @apply my-4px;
      @apply px-16px;
      @apply lg:mt-16px;
    }

    &-caption {
      @apply px-16px;
    }

    &-links {
      @apply flex;
      @apply my-16px;
      @apply px-16px;
      @apply gap-8px;
    }

    &-link {
      @apply rounded-1/2;
      @apply bg-surface;
    }
  }

  &__expandable-text-block {
    @apply px-16px;

    &-about {
      @apply text-on-surface-variant;
      @apply font-inter;
    }
  }

  &__category {
    @apply px-16px;
    // @apply hidden;
    @apply lg:block;
  }

  &__additional-list {
    @apply flex;
    @apply gap-16px;
    @apply py-16px;
  }

  &__amenities {
    @apply w-100%;
    @apply flex;
    @apply flex-col;
    @apply lg:flex-row;
    @apply lg:flex-wrap;
    @apply gap-24px;
    @apply my-16px;
    @apply pb-8px;

    &-amenity {
      @apply flex;
      @apply items-center;
      @apply lg:w-30%;
    }
  }

  &__locations,
  &__locations-mobile {
    @apply px-16px;
    @apply lg:px-0;
    @apply hidden;
    @apply lg:block;

    &-location {
      @apply block mt-16px;

      &:first-child {
        @apply mt-0;
      }
    }
  }

  &__locations-mobile {
    @apply block;
    @apply lg:hidden;
  }

  &__refund-cancellation {
    h3 {
      @apply font-optima font-semibold text-on-background text-md;
    }
    p {
      @apply font-inter text-on-surface-variant text-body-sm;
      @apply mt-8px;
    }
  }
}

.line {
  @apply border-outline-variant;
  @apply my-16px;

  &.line-mb {
    @apply mt-8px;
  }
}
