.notifications {
  @apply flex flex-col items-center gap-8px;
  @apply max-w-1200px w-100% h-100%;
  @apply p-16px;
  @apply bg-background;
  @apply overflow-y-scroll;

  &__wrapper {
    @apply fixed;
    @apply top-0 right-0 bottom-0;
    @apply z-30;
    @apply w-100%;
    @apply overflow-hidden;
    @apply lg:w-375px;
    @apply lg:shadow-evaluation-2;
    @apply xl:right-[calc(16px_+_(100%_-_1200px)/2)] xl:top-4px;
    @apply xl:rounded-8px;
    @media (min-height: 700px) {
      @apply xl:max-h-[90%] xl:h-fit box-border;
    }
    @media (min-height: 800px) {
      @apply xl:max-h-[80%] xl:h-fit box-border;
    }
  }

  &__header {
    @apply flex justify-between items-center;
    @apply relative;
    @apply w-100%;
    @apply p-12px lg:p-8px xl:py-12px;
    @apply bg-background;

    &-title {
      @apply font-inter font-semibold text-body-lg text-on-surface;
    }
  }

  &__item {
    @apply flex gap-16px;
    @apply w-100%;
    @apply cursor-pointer;

    &-image {
      @apply h-64px w-64px;
      @apply relative;
      @apply rounded-8px;

      img {
        @apply rounded-8px;
      }

      &--past {
        @apply bg-[#222E42] box-border;

        img {
          @apply opacity-70;
        }
      }

      &-star-icon {
        @apply absolute top-50% left-50% -translate-x-1/2 -translate-y-1/2;
        @apply h-24px w-24px;
        @apply scale-125;
        @apply fill-surface+2;
      }
    }
  }

  &__info {
    @apply flex flex-col justify-center;
    @apply w-[calc(100%_-_32px_-_48px)] box-border;
    @apply pr-8px;

    &-title {
      @apply flex justify-between w-100%;
      @apply font-inter font-semibold text-body-sm text-on-surface;

      &--past {
        @apply text-on-surface-variant;
      }
    }

    &-marker {
      @apply text-error;
      @apply opacity-100;

      &--hide {
        transition: opacity 0.4s;
        @apply opacity-0;
      }
    }

    &-content {
      @apply flex;
      @apply font-inter text-caption-lg text-on-surface-variant;

      &-truncated {
        @apply truncate;
        @apply min-w-0;
      }

      &-nowrap {
        @apply whitespace-nowrap;
      }
    }
  }

  &__empty {
    @apply flex flex-col items-center justify-center gap-8px;
    @apply h-[calc(100%_-_100px)] box-border;

    &-icon {
      @apply bg-tertiary-container;
      @apply pointer-events-none;
    }

    &-text {
      @apply flex flex-col justify-center gap-4px;
    }

    &-title {
      @apply font-inter font-semibold text-body-lg text-on-surface text-center;
    }

    &-caption {
      @apply font-inter text-caption-lg text-tertiary text-center;
    }
  }
}
