.slider {
  .slider-header {
    @apply flex items-center;

    .slider-header-title {
      @apply font-optima font-semibold text-md text-on-background;
      @apply whitespace-nowrap;
    }
  }

  .slider-navigation-top-right {
    @apply flex justify-end;
    @apply w-100%;
  }

  .slider-navigation-top-left {
    @apply flex items-center;
    @apply gap-16px;
  }

  .slider-navigation-top-center {
    @apply flex items-center justify-center;
    @apply w-100%;
  }

  .slider-navigation-bottom-right {
    @apply flex justify-end;
  }

  .slider-navigation-bottom-center {
    @apply flex justify-center;
  }

  .slider-navigation-top-full-w,
  .slider-navigation-bottom-full-w {
    @apply w-100%;
    .navigation {
      @apply justify-between;
    }
  }

  .slider-navigation-bottom {
    @apply -mt-8px;
  }

  .elements-list-wrapper {
    @apply relative;
    @apply my-8px;
  }

  .elements-list {
    @apply flex;
    @apply gap-16px;
    @apply overflow-x-hidden;
    @apply pb-8px;
  }

  .fade-out-left,
  .fade-out-right {
    @apply absolute;
    @apply bg-surface+2;
    @apply top-0;
    @apply min-h-100%;
    @apply w-32px;
  }

  .fade-out-left {
    @apply left-0;
    mask-image: linear-gradient(to right, black 0%, transparent 100%);
  }

  .fade-out-right {
    @apply right-0;
    mask-image: linear-gradient(to left, black 0%, transparent 100%);
  }
}
