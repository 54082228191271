.wavy-line {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: hidden;

  &__ellipse {
    position: absolute;
    background: radial-gradient(
      ellipse,
      transparent,
      transparent 9px,
      rgba(176, 172, 166, 1) 9px,
      rgba(176, 172, 166, 1) 10px,
      transparent 11px
    );
    background-size: 40px 40px;
    width: 110%;
    height: 20px;
    margin-left: -5%;
  }

  &__ellipse2 {
    top: 20px;
    left: 22px;
    background-position: -2px -20px;
  }
}
