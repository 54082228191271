.artist-select {
  &__artist-option {
    @apply flex gap-16px py-13px;
  }

  &__name {
    @apply font-inter font-semibold text-body-sm text-on-surface;
  }

  &__info {
    @apply font-inter font-normal text-caption-lg text-on-surface-variant;
  }

  &__link {
    @apply font-inter font-normal text-caption-lg text-tertiary;
  }

  &__center-col {
    @apply flex flex-2 flex-col;
  }

  &__options {
    @apply border-t border-surface-variant;
  }
}
