.location-info-link {
  &__body {
    @apply h-50px border-b border-background-sl-low flex items-center justify-between;
  }

  &__title {
    @apply flex items-center;
    @apply font-inter text-body-sm font-normal text-on-background;
    @apply fill-on-background;
  }
}
