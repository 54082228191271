.checkbox-form-element {
  &__body {
    @apply flex items-center;
  }

  &__label {
    @apply font-inter text-body-sm text-on-surface;
  }

  &__error-container {
    @apply -mt-7px pl-16px min-h-20px;
    @apply font-inter font-normal text-caption-lg text-error;
    & > span {
      @apply font-inter text-caption-lg text-error;
    }
  }
}
