.booking-details-page {
  @apply -mt-64px sm:mt-0;

  &__layout {
    @apply min-h-auto lg:min-h-[calc(100vh_-_72px)] box-border;
    @apply pb-110px lg:pb-16px;
  }

  &__left-side-title {
    @apply font-optima font-medium text-on-surface text-2xl;
    @apply mt-12px mb-24px;

    &:first-letter {
      @apply capitalize;
    }
  }

  &__payment-type {
    @apply flex items-center my-16px mx-18px;

    &--start {
      &__payment-type-left {
        @apply self-start;
      }
    }

    &-right {
      @apply flex-1 ml-18px flex flex-col;
      span:first-child {
        @apply font-inter text-body-sm font-semibold text-on-surface;
      }
      span:last-child {
        @apply font-inter text-caption-lg text-on-surface-variant;
      }
    }
  }

  &__location {
    @apply relative;
    @apply min-w-100%;

    &-cover-photo {
      img {
        @apply lg:rounded-t-16px;
      }
    }

    &-info {
      @apply absolute bottom-16px left-16px;
      @apply z-10;

      &-wrapper {
        @apply lg:w-[430px] box-border;
        @apply lg:rounded-16px;
        @apply lg:border lg:border-surface-variant;
      }

      &-bottom-controls {
        @apply hidden;
        @apply lg:flex gap-8px;
        @apply px-16px py-8px -mt-1px;
        @apply border-t border-t-surface-variant;
        @apply bg-background;
        @apply w-100%;
        @apply rounded-b-16px;
        .state-layer {
          width: 100%;
          .content {
            justify-content: center;
          }
          span {
            width: 100%;
          }
        }
      }

      &-gradient {
        @apply absolute bottom-0 left-0 right-0;
        @apply min-h-50%;
        @apply bg-gradient-to-b from-[rgba(17,23,32,0)] to-[rgba(17,23,32,0.7)] box-border;
      }

      &-name {
        @apply font-optima font-semibold text-md text-white;
      }

      &-address {
        @apply font-inter text-body-sm text-white;
      }
    }

    &-share-button {
      @apply hidden lg:block;
      @apply absolute bottom-16px right-16px;
    }
  }

  &__short-description {
    @apply p-16px;
    @apply flex flex-col gap-8px;
    @apply font-inter font-semibold text-body-sm;

    &--upcoming {
      @apply bg-primary-container;
      @apply text-primary;

      svg {
        @apply fill-primary;
      }
    }

    &--recent {
      @apply bg-secondary-container;
      @apply text-secondary;

      svg {
        @apply fill-secondary;
      }
    }

    &--cancelled {
      @apply bg-error-container;
      @apply text-on-error-container;

      svg {
        @apply fill-on-error-container;
      }
    }

    &-row {
      @apply flex items-center gap-8px;

      span {
        @apply font-normal;
      }
    }
  }

  &__links {
    @apply px-16px;
  }

  &__container {
    @apply px-16px lg:px-0;
    @apply lg:flex lg:flex-col lg:items-end;
    @apply lg:w-100%;
  }

  &__list-item {
    @apply flex items-center justify-between;
    @apply font-inter text-body-sm text-[#3B3B3B] box-border;

    &-container {
      @apply bg-background;

      .list-item-component__icon {
        @apply fill-on-surface-variant;
      }
    }

    &-left-side {
      @apply flex items-center gap-4px;
    }

    &-menu-icon {
      @apply fill-none;
      @apply mr-6px;
    }
  }

  &__details {
    @apply mt-23px lg:mt-0;

    &-title {
      @apply font-optima font-semibold text-on-background text-sm;
      @apply lg:hidden;
    }

    &-block {
      @apply mt-8px px-16px lg:pb-8px;
      @apply bg-surface+3 lg:bg-background;
      @apply border border-surface-variant;
      @apply rounded-8px lg:rounded-[12px] box-border;
      @apply lg:w-[430px] box-border;

      &--date-and-time {
        @apply h-[calc(100vh_-_180px)] box-border;
        @apply p-0;
      }
    }

    &-order {
      @apply flex gap-8px;
      @apply py-16px;
      @apply border-b border-b-surface-variant;

      &:last-child {
        @apply border-b-0;
      }

      &-left-side {
        @apply flex;

        &-avatar {
          @apply border-2 border-background;

          &:first-child {
            @apply z-10;
          }

          &:last-child {
            @apply -ml-8px;
          }
        }
      }

      &-right-side {
        @apply flex flex-col gap-16px;

        &-top {
          @apply flex items-center gap-4px;
        }

        &-caption {
          @apply font-inter text-caption-lg text-on-surface-variant;
        }

        &-name {
          @apply font-inter text-body-sm text-on-surface;
        }

        svg {
          @apply fill-on-surface-variant;
        }

        &-services {
          @apply font-inter text-body-sm text-on-surface;
        }
      }
    }

    &-payment {
      @apply flex items-center justify-between;
      @apply pb-8px;

      &-left-side {
        @apply flex items-center gap-16px;

        &-price {
          @apply font-inter font-semibold text-on-surface text-body-sm;
        }

        &-method {
          @apply font-inter text-on-surface-variant text-caption-lg;
        }
      }
    }

    &-buttons {
      @apply mt-16px;
      @apply lg:flex lg:gap-16px;
      @apply lg:w-[430px] box-border;
    }
  }

  &__bottom-controls {
    @apply fixed bottom-0;
    @apply flex gap-8px;
    @apply px-16px py-8px;
    @apply border-t border-t-surface-variant;
    @apply bg-background;
    @apply w-100%;
    @apply z-20;
    @apply lg:hidden;
    .state-layer {
      width: 100%;
      .content {
        justify-content: center;
      }
      span {
        width: 100%;
        white-space: normal !important;
      }
    }
  }

  &__cancel-button-wrapper {
    @apply px-16px py-8px;
    @apply bg-background;
    @apply border-t border-t-surface-variant;
  }

  &__mobile-date-and-time {
    @apply mt-0;
    @apply overflow-hidden;
  }
}
