.amenityItem {
  width: 32%;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 24px;
  @media only screen and (max-width: 1160px) {
    width: 48%;
  }
  @media only screen and (max-width: 992px) {
    width: 32%;
  }
  @media only screen and (max-width: 600px) {
    width: 48%;
    margin-top: 16px;
  }
  @media only screen and (max-width: 390px) {
    width: 100%;
  }
}
