.card-input {
  $base: &;
  @apply overflow-hidden rounded-8px;

  input {
    @apply bg-transparent border-0 font-inter outline-none text-center;
    @apply p-0;
    &::placeholder {
      @apply text-on-surface-variant;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &__dot {
    @apply font-inter text-caption-sm text-on-surface-variant mx-2px;
  }
  & &__card-input {
    @apply flex-1 text-left;
  }
  &__date-input {
    @apply w-60px;
  }
  &__cvc-input {
    @apply w-40px;
  }

  &__inputs-wrapper {
    @apply border border-outline-variant;
    @apply rounded-8px overflow-hidden;
  }
  &__inputs {
    @apply bg-surface+3 transition-colors;
    @apply border border-transparent;
    @apply flex items-center;
    @apply h-50px px-16px;
  }

  &--disabled {
    @apply bg-surface+3;
    @apply cursor-not-allowed;
    @apply opacity-40;

    input {
      @apply cursor-not-allowed;
    }
  }

  &:not(#{$base}--disabled) {
    #{$base}__inputs {
      &:hover {
        @apply bg-surface-sl-low;
      }
    }
  }

  &--focused {
    #{$base}__inputs-wrapper {
      @apply border-2 border-primary;
    }
    #{$base}__inputs {
      @apply border-0 h-48px;
    }
  }

  &--error {
    #{$base}__inputs-wrapper {
      @apply border-error;
    }
  }

  &__error-msg-container {
    @apply pt-4px pb-10px pl-16px min-h-32px;
    @apply font-inter font-normal text-caption-lg text-error;
  }
}
