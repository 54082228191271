.phone-input {
  [type="text"],
  input:where(:not([type])),
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0px;
  }

  [type="text"]:focus,
  input:where(:not([type])):focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0px;
  }
}

.react-international-phone-country-selector-button {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  padding-inline: 10px !important;
  background-color: #faf9f7 !important;
}

.react-international-phone-input-container {
  display: flex;
  width: 100%;
  border: 1px solid rgba(204 199 192);
  background-color: rgb(250 249 247 / var(--tw-bg-opacity));
  border-radius: 8px;
  height: 56px;
}

.react-international-phone-input-container .react-international-phone-input {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  outline: none !important;
  border: none !important;
  opacity: 1 !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  background-color: #faf9f7 !important;
  position: relative !important;
  height: 100% !important;
  border-radius: 8px !important;
  font-family: Inter, sans-serif !important;
}

.react-international-phone-input-container
  .react-international-phone-input:focus {
  outline: none !important;
  padding: 0 8px !important;
}

.react-international-phone-country-selector-button {
  width: 100% !important;
  height: 100% !important;
}

.react-international-phone-country-selector-dropdown {
  background-color: rgba(255, 255, 255, 1) !important;
  color: black !important;
  z-index: 1000 !important;
  outline: none !important;
  border-radius: 8px !important;
  border: 1px solid rgba(204 199 192) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.react-international-phone-country-selector-dropdown__list-item {
  padding: 8px 16px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  font-family: Inter, sans-serif !important;
  background-color: rgb(250 249 247 / var(--tw-bg-opacity)) !important;
}

.react-international-phone-country-selector-dropdown__list-item:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
