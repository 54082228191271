.loading-element {
  &__content {
    @apply max-w-240px flex flex-col items-center;
  }

  &__icon {
    @apply mb-16px;
    @apply animate-spin;
  }

  &__title {
    @apply text-center;
  }

  &__description {
    @apply font-inter text-body-sm text-outline text-center;
  }
}
