.me-page {
  @apply flex flex-col items-center grow;
  @apply w-100% lg:min-h-[calc(100vh_-_72px)] box-border;
  @apply pb-16px;
  @apply lg:flex-row lg:gap-28px;
  @apply lg:pt-20px lg:px-24px;

  &__user {
    @apply flex flex-col items-center gap-16px;
    @apply w-100%;
    @apply pt-24px pb-32px;
    @apply border-b-4 border-error-container;
    @apply bg-[#F9F4F4] box-border;
    @apply lg:relative;
    @apply lg:py-40px;
    @apply lg:min-w-375px lg:max-w-375px lg:h-[max(765px,calc(100vh_-_120px))] box-border;
    @apply lg:border-4 lg:rounded-16px lg:outline lg:outline-4 lg:outline-offset-0 lg:outline-tertiary-container;

    &-info {
      @apply text-center;

      &-name {
        @apply font-optima font-medium text-on-surface text-lg;
      }

      &-upcoming-pointments {
        @apply font-inter font-semibold text-body-lg;
        @apply flex gap-4px items-center justify-center;

        &-cards {
          @apply w-100%;
          @apply flex gap-8px;
          @apply overflow-scroll;
          @apply px-16px;
          @apply lg:flex-col;
        }

        &-card {
          @apply lg:w-100%;
        }
      }

      &-recent-pointments-card-cancelled {
        .suggestion-chip {
          @apply bg-error-container text-on-error-container;
        }
      }

      &-book-button {
        @apply hidden;
        @apply absolute bottom-16px;
        @apply z-40;
        @apply w-[calc(100%_-_32px)] box-border;
        @apply lg:block;
      }
    }
  }

  &__sections-wrapper {
    @apply w-100%;
    @apply border-t-4 border-tertiary-container;
    @apply bg-background;
    @apply flex flex-col items-center justify-center grow;
    @apply lg:border-0;
    @apply lg:w-[calc(100%_-_400px)] box-border;
  }

  &__sections {
    @apply w-100%;

    &:first-child {
      @apply pt-16px lg:pt-0;
    }
  }

  &__section {
    @apply py-8px;
    @apply border-b border-surface-variant;

    &:last-child {
      @apply border-b-0;
    }

    &-header {
      @apply flex justify-between items-center;
      @apply px-16px;

      &-tittle {
        @apply font-inter font-semibold text-body-lg text-on-surface;
      }
    }

    &-content {
      @apply flex gap-8px;
      @apply px-16px pt-8px pb-16px;
      @apply overflow-scroll;
    }
  }

  &__slider {
    .slider-header {
      .slider-header-title {
        @apply font-inter text-body-lg;
      }
    }
    @apply pt-24px pb-8px;
    @apply border-b border-surface-variant;

    &:last-child {
      @apply border-b-0;
    }
  }

  &__location {
    @apply min-w-320px min-h-[190px] box-border;

    &:not(&:last-child) {
      @apply mr-8px lg:mr-0;
    }
  }

  &__empty-section {
    @apply flex flex-col justify-center items-center gap-8px;
    @apply py-60px px-16px;
    @apply border-b border-surface-variant;

    &-icon {
      @apply h-40px w-40px;
      @apply bg-tertiary-container;
      @apply rounded-1/2;
      @apply flex items-center justify-center;
      @apply fill-on-tertiary-container;
    }

    &-title {
      @apply font-inter font-semibold text-body-lg text-on-surface text-center;
    }

    &-caption {
      @apply font-inter text-caption-lg text-on-surface-variant text-center;
    }

    &:last-child {
      @apply border-b-0;
    }
  }

  &-book-button-wrapper {
    @apply sticky bottom-0;
    @apply w-100%;
    @apply px-16px py-8px;
    @apply border-t border-surface-variant;
    @apply bg-background;
    @apply z-20;
    @apply lg:hidden;
  }
}
