.location-main-data {
  &__title {
    @apply sm:hidden lg:block;
  }

  &__info {
    @apply flex font-inter font-semibold text-body-sm text-on-background -ml-3px mb-2px;
    @apply md:mb-0;
  }

  &__rating-icon {
    @apply scale-75 fill-primary;
  }

  &__address {
    @apply font-inter font-normal text-body-sm text-on-background;
  }
}
