.delete-account-section {
  @apply flex flex-col items-center gap-24px;
  @apply px-32px py-24px;
  @apply lg:px-0 lg:py-0;

  &__trash {
    @apply h-64px w-64px;
    @apply rounded-1/2;
    @apply flex items-center justify-center;
    @apply bg-error;

    &-icon {
      @apply fill-on-error;
    }
  }

  &__text {
    @apply font-inter font-semibold text-body-lg text-on-surface;
    @apply text-center;
  }

  &__button {
    @apply bg-error-container #{!important};
    @apply text-on-error-container #{!important};
  }
}
