.location-working-hours {
  $base: &;
  &__working-hours-header {
    @apply h-50px flex items-center font-inter font-normal text-body-sm text-on-background fill-on-background;
  }

  &__working-hours-item {
    @apply font-inter font-normal text-body-sm py-4px text-on-background fill-on-background;
    @apply flex justify-between items-center;

    &:first-child {
      @apply pt-0;
    }

    &:last-child {
      @apply pb-0;
    }

    &-marker {
      @apply w-30px block;
    }

    &--active {
      @apply font-semibold;

      #{$base}__working-hours-item-marker {
        @apply flex items-center justify-center;

        &:after {
          @apply block rounded-1/2 w-4px h-4px;
          @apply bg-on-background;
          content: "";
        }
      }
    }
  }
}
