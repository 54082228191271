.location-page {
  $base: &;
  @apply -mt-64px sm:mt-0;

  .right-col {
    @apply lg:sticky lg:top-88px;
  }

  &__main {
    @apply sm:flex sm:px-16px sm:mt-18px;
    @apply lg:block lg:px-0 lg:pb-0 lg:mt-0;

    &-content {
      @apply hidden gap-24px;
      @apply lg:flex lg:mt-24px;
      @apply xl:gap-32px;

      &-left {
        @apply flex-1 overflow-hidden;
      }

      &-right {
        @apply w-185px min-w-185px;
      }
    }

    &-description {
      @apply px-16px overflow-hidden mt-42px;
      @apply sm:px-0 sm:pl-16px sm:-mr-16px sm:pr-16px sm:mt-0;
      @apply lg:hidden;

      &-slots {
        @apply pt-16px pb-10px;
        @apply lg:flex lg:gap-8px;
        @apply lg:border-b lg:border-background-sl-low;

        .chips-swiper {
          @apply -mx-16px px-16px;

          .swiper-wrapper {
            .chip-slide {
              @apply w-77px;
            }
          }
        }
      }

      &-amenities {
        @apply flex gap-16px flex-wrap;
      }
    }
  }

  &__menu {
    @apply lg:sticky lg:top-88px;

    &-header {
      @apply hidden lg:block;
    }

    &-container {
      @apply overflow-hidden mt-16px;
      @apply lg:mt-0;
      @media (min-width: 992px) {
        overflow: initial;
      }
    }

    &-content {
      @apply border border-surface-variant -mx-1px;
      @media (min-width: 992px) {
        height: calc(100dvh - 150px);
      }
    }
  }

  &__breadcrumbs-wrapper {
    @apply hidden lg:block;
  }

  &__cover-photo {
    @apply overflow-hidden;
    @apply sm:w-125px sm:min-w-125px sm:h-165px sm:object-cover sm:rounded-4px sm:mb-0;
    @apply md:w-290px;
    @apply md:rounded-8px;
    @apply lg:w-auto lg:rounded-16px;
    animation: appear 0.75s ease-in;

    img {
      object-position: top;
    }
  }

  &__head {
    &-container {
      @apply relative;
      @apply sm:block;
    }
  }

  &__company-logo-container {
    @apply absolute z-10 -bottom-28px left-16px;
    @apply sm:hidden;
    @apply lg:relative lg:block lg:bottom-auto lg:left-auto;

    #{$base}__company-logo {
      @apply border-2 -mt-28px z-10 bg-transparent border-background bg-surface;
      @apply lg:mt-0 lg:absolute lg:top-16px lg:left-16px lg:border-on-background-sl-hight;
    }
  }

  &__favorite-button-container {
    @apply hidden absolute z-10;
    @apply lg:block top-16px right-16px;

    #{$base}__favorite-button.default {
      @apply bg-background shadow-evaluation-2;
    }
  }

  .expandable-element {
    &__content {
      @apply min-h-32px my-9px flex flex-wrap gap-8px;
    }

    &__control {
      @apply mt-14px;
    }
  }

  &__category {
    @apply py-20px border-b border-background-sl-low;

    &-additional {
      @apply py-5px flex items-center gap-16px;
    }
  }

  &__artists {
    @apply flex gap-8px;
    @apply overflow-scroll;
    @apply px-16px;
    @apply border-none;

    &-title {
      @apply px-16px -mb-8px mt-20px;
    }
  }

  &__additional {
    @apply py-20px;

    &-list-item.list-item-component {
      @apply bg-transparent;
    }
  }

  &__modal-title {
    @apply mb-24px text-error;
  }

  &__refund-cancellation {
    h3 {
      @apply font-optima font-semibold text-on-background text-md;
    }
    p {
      @apply font-inter text-on-surface-variant text-body-sm;
      @apply mt-8px;
    }
  }
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
