.artist-page {
  @apply -mt-64px sm:mt-0;

  &__container {
    @apply flex flex-col items-center h-100%;
    @apply lg:px-24px pt-8px pb-72px;
    @apply lg:pb-16px;
  }

  &__header {
    @apply flex flex-row justify-between;
    @apply w-100%;
    @apply px-24px lg:px-0;
  }

  &__header > div {
    @apply w-50% lg:w-33% box-border;
  }

  &__photos {
    @apply relative;
    @apply flex justify-center;
    @apply mt-8px;
  }

  &__artist-avatar {
    @apply w-134px h-134px box-border;
    @apply sm:w-166px sm:h-166px lg:w-192px lg:h-192px box-border;
  }

  &__company_logo {
    @apply absolute -bottom-28px;
    @apply border-2 border-surface+2;
  }

  &__heart-button {
    @apply ml-auto;
  }

  &__info {
    @apply flex flex-col items-center;
    @apply mt-40px;
    @apply px-24px lg:px-0;
  }

  &__name {
    @apply text-on-background box-border;
  }

  &__location-name {
    @apply flex gap-2px;
    @apply font-inter font-semibold text-on-background text-body-sm;
  }

  &__address {
    @apply font-inter text-on-background text-body-sm;
  }

  &__slots {
    @apply my-8px;
    @apply flex gap-8px;
  }

  &__expandable_slots {
    @apply flex gap-8px;
  }

  &__bio {
    @apply mt-8px mb-16px;
    @apply max-w-768px box-border;
    @apply font-inter text-body-sm text-on-surface-variant text-center;
  }

  hr {
    @apply w-100% border-surface-variant;
  }

  &__book-button-mobile-wrapper {
    @apply fixed bottom-0;
    @apply w-100%;
    @apply border-t border-surface-variant;
    @apply px-16px py-8px;
    @apply bg-background;
    @apply z-10;
  }

  &__book-button-mobile {
    @apply w-100%;
  }

  &__insta-photos {
    @apply w-100%;
    @apply mt-24px;

    &-header {
      @apply flex items-center justify-between;
      @apply w-100%;
      @apply px-24px lg:px-0;

      &-left-side {
        @apply flex items-center gap-8px;

        svg {
          @apply fill-on-background;
        }
      }

      &-title {
        @apply text-on-background;
      }

      &-button {
        .state-layer {
          .content {
            @apply text-on-tertiary-container;
          }
        }
      }
    }

    &-content {
      @apply flex gap-8px lg:gap-16px;
      @apply overflow-x-auto lg:overflow-x-hidden;
      @apply mt-16px;
      @apply px-24px lg:pl-0;
      @apply w-100%;

      &-photo {
        @apply w-146px h-146px box-border;
        @apply lg:w-auto lg:h-auto lg:min-w-141px lg:min-h-141px lg:max-w-178px lg:max-h-178px box-border;
        @apply object-cover aspect-square;
        @apply rounded-8px;
      }
    }
  }

  &__rating {
    @apply mt-32px;
    @apply w-100%;

    &-header {
      @apply flex items-center justify-between;
      @apply w-100%;
      @apply px-24px lg:px-0;

      &-left-side {
        @apply flex items-center gap-8px;

        svg {
          @apply fill-primary;
        }
      }

      &-title {
        @apply text-on-background;
      }
    }
  }

  &__reviews {
    @apply py-16px;
    @apply px-24px lg:px-2px;

    .fade-out-left,
    .fade-out-right {
      @apply absolute;
      @apply bg-surface+2;
      @apply top-0;
      @apply min-h-100%;
      @apply z-30;
    }

    .fade-out-left {
      @apply w-24px;
      @apply left-0;
      mask-image: linear-gradient(to right, black 0%, transparent 100%);
    }

    .fade-out-right {
      @apply w-64px;
      @apply right-0;
      mask-image: linear-gradient(to left, black 0%, transparent 100%);
    }
  }
}
