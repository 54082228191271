.menu {
  @apply mx-16px;
  @apply lg:mx-0;
  @apply lg:w-[280px] box-border;
  @apply lg:shrink-0;

  &__first-section,
  &__second-section {
    @apply px-16px;
    @apply rounded-8px;
    @apply border border-surface-variant;
    @apply bg-surface+3;
    @apply lg:border-0;
    @apply lg:bg-background;
    @apply lg:rounded-0;
    @apply lg:px-0;
  }

  &__second-section {
    @apply mt-16px;
    @apply lg:mt-0;

    hr {
      @apply lg:border-outline-variant;
      @apply lg:mx-16px lg:my-8px;
    }
  }

  &__list-item {
    @apply cursor-pointer;
    @apply lg:h-56px;
    @apply lg:flex lg:items-center;
    @apply lg:px-16px;

    &:last-child {
      @apply border-0;
    }

    &--active {
      @apply bg-surface;
      @apply rounded-30px;
    }
  }

  &__item {
    @apply flex items-center gap-16px;
    @apply font-inter font-semibold text-body-sm text-on-surface;
    @apply lg:text-on-surface-variant;

    svg {
      @apply lg:opacity-70;
    }

    &--active {
      @apply lg:text-on-surface;

      svg {
        @apply lg:opacity-100;
      }
    }
  }

  &__delete-list-item {
    svg {
      @apply fill-error;
    }
  }

  &__delete-item {
    @apply text-error;
  }
}
