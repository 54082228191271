.header {
  $base: &;
  @apply h-64px lg:h-72px;
  @apply sticky z-20 top-0 left-0 right-0;
  @apply border-b border-surface-variant;

  &--non-transparent {
    @apply bg-background border-b;
  }

  &__container {
    @apply max-w-1200px m-auto;
    @apply flex h-64px lg:h-72px px-12px lg:px-24px;
  }

  &__home {
    @apply bg-[#F9F4F4] box-border;
  }

  &__column {
    @apply flex flex-1 items-center;
  }

  &__left {
    @apply justify-start;
  }

  &__center {
    @apply flex-4 lg:flex-3 justify-center;
  }

  &__right {
    @apply justify-end gap-16px;
  }

  &__header-logo {
    @apply hidden lg:block;
  }

  &--type-1 {
    @apply border-b-0;
    @apply sm:border-b;

    &#{$base}--non-transparent {
      @apply border-b;
    }
  }

  &--type-2 {
    @apply border-b-0;
    @apply lg:border-b;

    &#{$base}--non-transparent {
      @apply border-b;
    }
  }

  &--type-3 {
    @apply border-b bg-background;
  }
}
