.switch {
  @apply relative;
  @apply inline-block;
  @apply w-52px h-32px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    @apply absolute;
    @apply cursor-pointer;
    @apply top-0 left-0 right-0 bottom-0;
    @apply bg-surface-variant;
    @apply border-2 border-outline;
    @apply rounded-100px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      @apply absolute;
      content: "";
      @apply w-24px h-24px;
      @apply left-2px bottom-2px;
      @apply bg-outline;
      @apply rounded-1/2;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  &--primary {
    input:checked + .switch__slider {
      @apply bg-primary;
      @apply border-primary;
    }
  }

  &--secondary {
    input:checked + .switch__slider {
      @apply bg-secondary;
      @apply border-secondary;
    }
  }

  &--tertiary {
    input:checked + .switch__slider {
      @apply bg-tertiary;
      @apply border-tertiary;
    }
  }

  &--error {
    input:checked + .switch__slider {
      @apply bg-error;
      @apply border-error;
    }
  }

  &--success {
    input:checked + .switch__slider {
      @apply bg-success;
      @apply border-success;
    }
  }

  input:checked + &__slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    @apply bg-on-primary;
  }

  &--disabled {
    @apply cursor-not-allowed;
    @apply opacity-40;
  }
}
