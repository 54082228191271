@import "mixins";

.expanded-text {
  @apply relative;
  @apply font-inter;

  &__content--truncate-lines {
    @apply overflow-hidden relative;
    @include line-clamp(2);
  }

  &__read-more {
    @include line-clamp(2);
    @apply absolute right-0 bottom-0;
    @apply font-semibold text-on-tertiary-container;
    @apply cursor-pointer;
    @apply pl-15px;
    background: linear-gradient(
      90deg,
      rgba(246, 244, 241, 0) 0%,
      rgba(246, 244, 241, 1) 12%
    );
  }
}
