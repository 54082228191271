.toast {
  $base: &;
  @apply relative px-40px py-5px;
  @apply font-inter text-caption-lg;

  &__close-button {
    @apply absolute right-0 top-0 w-40px h-40px cursor-pointer;
    @apply flex justify-center items-center;
  }

  &__content {
    @apply flex items-center justify-center;
  }

  &__icon {
    @apply mr-4px min-w-24px;
  }

  &__message {
    @apply py-6px;
  }

  &--rounded {
    @apply rounded-8px;
  }

  &--success {
    @apply bg-success-container;
    svg {
      @apply fill-on-success-container;
    }
    #{$base}__message {
      @apply text-on-success-container;
    }
  }

  &--error {
    @apply bg-error-container;
    svg {
      @apply fill-on-error-container;
    }
    #{$base}__message {
      @apply text-on-error-container;
    }
  }

  &--warning {
    @apply bg-primary-container;
    svg {
      @apply fill-on-primary-container;
    }
    #{$base}__message {
      @apply text-on-primary-container;
    }
  }

  &--info {
    @apply bg-tertiary-container;
    svg {
      @apply fill-on-tertiary-container;
    }
    #{$base}__message {
      @apply text-on-tertiary-container;
    }
  }
}
