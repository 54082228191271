.header-menu {
  $base: &;
  @apply fixed;
  @apply top-0 left-0 bottom-0;
  @apply flex justify-center;
  @apply z-30;
  @apply w-100%;
  @apply overflow-hidden;
  @apply lg:w-375px;
  @apply lg:shadow-evaluation-2;
  @apply xl:left-[calc(16px_+_(100%_-_1200px)/2)] xl:top-4px;
  @apply xl:rounded-8px;
  @media (min-height: 700px) {
    @apply xl:h-[90%] box-border;
  }
  @media (min-height: 800px) {
    @apply xl:h-[80%] box-border;
  }

  &__menu {
    @apply flex flex-col items-center;
    @apply max-w-1200px w-100% h-100%;
    @apply px-4px py-16px;
    @apply lg:px-0 lg:pb-0;
    @apply bg-background;
    @apply lg:bg-surface+3;

    &-header {
      @apply flex;
      @apply relative;
      @apply w-100%;
      @apply px-8px;

      &-info {
        @apply absolute left-1/2 translate-x-[-50%] box-border;
        @apply flex items-center gap-8px;
        @apply font-inter font-semibold text-body-lg text-on-surface;
      }
    }

    &-item {
      @apply w-100% h-56px;
      @apply flex gap-16px items-center;
      @apply px-16px;
      @apply font-inter font-semibold text-body-sm text-on-surface;
      @apply cursor-pointer;

      &:hover {
        @apply bg-background-sl-low;
      }
    }

    hr {
      @apply w-[calc(100%_-_32px)] box-border;
      @apply border-surface-variant;
    }

    &-bottom {
      @apply flex flex-col gap-16px;
      @apply w-100%;
      @apply px-12px pb-8px mt-auto;

      &-app-info {
        @apply flex items-center;
        @apply lg:justify-between;
      }

      &-left-side {
        @apply flex items-center;
        @apply lg:flex-col lg:items-start;
      }

      &-icon {
        @apply h-56px w-56px;
      }

      &-caption {
        @apply h-56px;
        @apply py-12px px-16px;
        @apply font-inter font-semibold text-caption-lg text-on-surface uppercase tracking-[0.04em] box-border;
        @apply lg:px-0;
        @apply lg:w-[107px] box-border;

        &-download {
          @apply text-primary;
        }
      }
    }
  }

  &__wrapper {
    @apply w-full;
  }

  &--not-logged-in {
    @apply top-63px;

    #{$base}__wrapper {
      background: rgba(17, 23, 32, 0.45);
    }

    #{$base}__menu-default {
      @apply gap-16px p-24px h-auto;
    }

    #{$base}__menu-button {
      @apply w-full;
    }
  }
}
