.credit-card {
  @apply flex items-center;
  @apply overflow-scroll;
  @apply lg:overflow-visible;
  @apply lg:relative;

  &--hovered {
    @apply bg-surface-sl-low;

    &:first-child {
      @apply lg:rounded-t-8px;
    }

    &:last-child {
      @apply lg:rounded-b-8px;
    }
  }

  &--deleting {
    @apply bg-surface+3;
    @apply opacity-40;
    @apply pointer-events-none cursor-not-allowed;
  }

  &__delete {
    @apply min-w-100px min-h-56px;
    @apply flex items-center justify-center;
    @apply bg-error;
    @apply lg:min-w-auto h-auto;
    @apply lg:bg-transparent;
    @apply lg:absolute -right-56px;
    @apply lg:pl-16px lg:pr-4px;

    svg {
      @apply fill-on-error;
      @apply scale-75;
    }
  }

  &__container {
    @apply flex items-center justify-between;
    @apply min-w-100%;
    @apply py-8px px-16px;
    @apply cursor-pointer;

    &-left-side {
      @apply flex items-center gap-16px;
    }

    &-title {
      @apply font-inter font-semibold text-body-sm text-on-surface;
    }

    &-default {
      @apply font-normal text-on-surface-variant;
    }

    &-expiry {
      @apply font-inter text-caption-lg text-on-surface-variant;
    }
  }
}
