.segmented-control {
  $base: &;
  @apply flex gap-5px;

  &__avatar {
    @apply mr-4px -ml-9px border border-surface-variant;
  }

  &__logo {
    @apply scale-75 -mr-15px -ml-15px;
  }

  &__button {
    @apply font-inter text-body-lg text-on-surface;
    @apply flex items-center;
    @apply cursor-pointer;
    @apply border border-transparent rounded-100px px-12px py-3px;
    @apply transition-colors;

    svg {
      @apply fill-on-surface;
    }

    &--active {
      @apply font-semibold;
      @apply bg-surface+3 shadow-evaluation-1;
    }

    &:not(&--active) {
      #{$base}__title {
        letter-spacing: 0.28px;
      }
      &:hover {
        @apply border-outline-variant;
      }
    }
  }
}
