.account-details-page {
  &__top-block {
    @apply flex justify-center;
    @apply relative;

    &-breadcrumbs {
      @apply absolute left-0 top-32px;
    }

    &-center {
      @apply w-720px;
    }
  }

  &__divider {
    @apply flex items-center;

    hr {
      @apply w-100%;
      @apply border-surface-variant;
      @apply pb-6px -ml-4px;
    }
  }

  &__content-wrapper {
    @apply lg:flex lg:justify-center;
    @apply lg:mt-16px;
  }

  &__content {
    @apply lg:w-720px;
    @apply lg:flex lg:gap-48px;
  }

  &__mobile-menu {
    @apply flex flex-col gap-24px;
  }
}
