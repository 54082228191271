.reset-password-form {
  &__successfully {
    @apply flex flex-col items-center;
    @apply mt-48px md:mt-24px;

    &-checkmark {
      @apply border-2 border-success rounded-1/2;
      @apply h-64px w-64px;
      @apply flex justify-center items-center;

      &-icon {
        @apply fill-success;
        @apply scale-200;
      }
    }

    &-title {
      @apply font-medium text-xl text-success;
      @apply mt-24px;
    }

    &-text {
      @apply mt-8px;
      @apply text-center;

      &-message {
        @apply font-inter text-body-lg text-on-surface-variant;
      }

      &-email {
        @apply font-semibold text-on-surface;
      }
    }

    &-button {
      @apply mt-32px;
    }
  }

  &__message {
    @apply font-inter text-body-lg text-on-surface-variant;
    @apply mt-16px;
  }

  &__form {
    @apply mt-24px;

    &-controls {
      @apply flex gap-16px;
    }
  }
}
