.payment-section {
  $base: &;

  &__content {
    @apply px-16px lg:px-0;
  }

  &__title {
    @apply hidden lg:block;
  }

  &__message {
    @apply hidden font-inter text-body-lg text-on-surface-variant mt-16px;
    @apply lg:block;
  }

  &__category-type {
    @apply mt-24px;
  }

  &__category-title {
    @apply font-inter text-body-lg text-on-background font-semibold;
  }

  &__options-container {
    @apply border border-surface-variant rounded-8px overflow-hidden bg-surface+3 mt-8px;
  }

  &__payment-type {
    @apply flex items-center my-16px mx-18px;

    &--start {
      #{$base}__payment-type-left {
        @apply self-start;
      }
    }

    &-right {
      @apply flex-1 ml-18px flex flex-col;
      span:first-child {
        @apply font-inter text-body-sm font-semibold text-on-surface;
      }
      span:last-child {
        @apply font-inter text-caption-lg text-on-surface-variant;
      }
    }
  }

  &__cancellation-policy {
    @apply block font-inter text-caption-lg text-tertiary cursor-pointer mt-8px;
  }

  &__payment-methods {
    .payment-form__payment-method:last-child {
      @apply border-b-0;
    }
  }

  &__payment-method {
    @apply flex items-center py-16px mx-18px cursor-pointer border-b border-b-outline-variant;

    &-label {
      @apply font-normal text-on-surface-variant text-body-sm;
    }

    &-left {
      @apply flex-1 flex items-center;
      & > span {
        @apply font-inter text-body-sm font-semibold text-on-surface;
      }
    }

    &-content {
      @apply flex-1 ml-18px flex flex-col;
      & > span:first-child {
        @apply font-inter text-body-sm font-semibold text-on-surface;
      }
      & > span:last-child {
        @apply font-inter text-caption-lg font-normal text-on-surface-variant;
      }
    }
  }

  &__new-card-container {
    @apply mt-8px mb-32px;
  }

  &__add-card-btn {
    @apply w-100%;
  }

  &__pay-btn-container {
    @apply fixed bottom-0 left-0 right-0 px-16px pb-34px border-t border-t-surface-variant;
    @apply lg:static lg:px-0 lg:border-t-0;
  }

  &__pay-btn {
    @apply w-100% mt-7px lg:mt-8px;
  }

  &__wavy-line {
    @apply hidden lg:block;
  }
  .StripeElement {
    padding: 20px 12px;
    border: solid 1px #ccc7c0;
    border-radius: 8px;
  }
  .StripeElement--focus {
    border: solid 1px #b8722a;
  }
}
