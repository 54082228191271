.accordion {
  $base: &;

  &__header {
    @apply flex items-center gap-5px;

    &-data {
      @apply flex-2 overflow-hidden;
    }
  }

  &__body {
    @apply border-b border-background-sl-low pb-16px overflow-hidden;
    @apply transition-all;
  }

  &__header-icon {
    @apply cursor-pointer transition-all;
    @apply flex self-stretch items-center;
    svg {
      @apply scale-75;
    }
  }

  &--expanded {
    #{$base}__body {
      @apply h-auto pb-16px;
    }

    #{$base}__header-icon {
      @apply rotate-180;
    }
  }

  &--collapsed {
    #{$base}__body {
      @apply h-0 py-0;
    }
  }
}
