.confirmation-modal {
  @apply z-[999999] box-border;

  &__background {
    @apply z-[999998] box-border;
  }

  &__text {
    @apply font-inter text-body-lg text-on-background;
    @apply p-16px;
  }

  &__buttons-wrapper {
    @apply flex gap-8px;
    @apply px-16px py-8px;
    @apply bg-background;
    @apply border-t border-t-surface-variant;
  }
}
