.sliding-modal-background {
  background: rgba(7, 9, 13, 0.6);
  @apply h-100% w-100%;
  @apply z-99998 box-border;
  @apply fixed bottom-0 left-0;
  @apply lg:absolute;
}

.sliding-modal {
  @apply fixed bottom-0 left-0;
  @apply z-99999 box-border;
  @apply w-100%;
  @apply overflow-hidden;
  @apply lg:absolute;

  &--transparent-header {
    @apply overflow-hidden rounded-t-16px;
  }

  &__header {
    @apply flex justify-between items-center;
    @apply w-100% h-64px;
    @apply relative;
    @apply border-b border-b-surface-variant;
    @apply bg-background;
    @apply px-8px;

    &--rounded {
      @apply rounded-t-16px;
    }

    &--transparent {
      @apply absolute z-10 border-0 bg-transparent;
    }

    &-title {
      @apply absolute left-1/2 -translate-x-50% box-border;
      @apply font-inter font-semibold text-body-lg text-on-surface;
    }
  }

  &__content-wrapper {
    @apply flex flex-col h-100%;
    @apply bg-background;

    &--with-header {
      @apply h-[calc(100%_-_64px)] box-border;
    }
  }

  &__content {
    @apply h-100%;
    @apply overflow-hidden;

    &--scrollable {
      @apply overflow-y-auto;
    }
  }
}
