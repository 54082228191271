.toc-privacypolicy-footer {
  @apply flex flex-col-reverse items-start gap-4 md:justify-between md:flex-row md:items-start;

  &__copy-right {
    @apply text-caption-sm text-on-background;
  }

  &__nav {
    @apply flex items-center gap-2 md:gap-4;
  }

  &__nav-item-divider {
    @apply w-[2px] h-[2px] rounded-full bg-outline;
  }

  &__nav-item {
    @apply text-caption-sm text-on-background font-semibold;
  }
}
