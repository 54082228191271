.date-and-time-container {
  @apply bg-surface+2;
  @apply rounded-t;
  @apply relative;
  @apply flex flex-col;
  @apply min-h-[calc(100dvh_-_66px)] box-border;
  @apply overflow-hidden;
  @apply lg:overflow-hidden;
  @apply lg:shadow-evaluation-2;
  @apply lg:rounded-16px;
  @apply lg:mt-0;
  @apply lg:min-h-100%;

  .date-and-time-wrapper {
    @apply h-100%;
    @apply flex-grow-1 flex flex-col;

    .no-data {
      @apply flex flex-1 items-center justify-center;
      @apply font-inter font-medium text-body-lg text-on-background;
    }
  }

  .info {
    @apply flex flex-col items-center;
    @apply p-16px;
    @apply z-30;
    @apply bg-surface+2;
    @apply relative;

    @media (min-width: 992px), (max-height: 636px) {
      @apply py-0 px-8px;
    }

    @media (min-width: 1200px) and (min-height: 637px) {
      @apply p-16px;
    }

    .back-icon {
      @apply absolute top-8px lg:top-12px xl:top-8px left-8px;
    }

    .info-header {
      @apply hidden;
      @apply h-64px w-100%;
      @apply border-b border-b-surface-variant;

      @media (min-width: 992px), (max-height: 636px) {
        @apply grid grid-cols-[84px_1fr_84px] box-border;
      }

      @media (min-width: 1200px) and (min-height: 637px) {
        @apply hidden;
      }

      .middle-column {
        @apply flex flex-col items-center justify-center;

        .title {
          @apply font-inter font-semibold text-body-lg text-on-surface;
        }

        .caption {
          @apply font-inter text-caption-lg text-on-surface;
        }
      }

      .right-column {
        @apply flex items-center justify-center;
      }
    }

    .people-icons {
      @media (min-width: 992px), (max-height: 636px) {
        @apply hidden;
      }

      @media (min-width: 1200px) and (min-height: 637px) {
        @apply flex;
      }

      svg {
        @apply fill-primary;
        @apply bg-surface+2 rounded-1/2;
      }

      :not(:first-child) {
        @apply -ml-4px;
      }
    }

    .text {
      @apply text-sm font-semibold text-on-background;
      @apply mt-16px;
      @apply text-center;

      @media (min-width: 992px), (max-height: 636px) {
        @apply hidden;
      }

      @media (min-width: 1200px) and (min-height: 637px) {
        @apply block;
      }
    }
  }

  .artists-wrapper {
    @apply bg-surface+3;
    @apply border-t border-t-surface-variant;
    @apply rounded-t-16px;
    @apply z-30;

    .artists-header {
      @apply flex justify-between items-center;
      @apply px-16px pt-8px;

      .artists-title {
        @apply font-inter font-semibold text-body-sm text-on-surface;
      }
      .assignments-button {
        color: #16667a;
        font-size: 15px;
        :hover {
          color: #16667a;
        }
        svg {
          fill: #16667a !important;
        }
        .state-layer {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }

    .artists-list {
      @apply flex;
      @apply gap-16px;
      @apply overflow-x-scroll;

      @media (min-width: 992px), (max-height: 636px) {
        @apply pt-4px;
      }

      .artist {
        @apply flex flex-col items-center;
        @apply cursor-pointer;
        @apply min-w-60px;

        .avatars {
          @apply flex;
        }

        .user-avatar {
          @apply -ml-10px;

          span {
            @apply text-outline;
          }
        }

        .selected-artist {
          @apply border-4 border-primary-container;
          @apply outline outline-2 outline-primary;
        }

        .artist-name {
          @apply text-3xs text-on-background font-semibold font-inter;
          @apply text-center;
        }

        .artist-caption {
          @apply font-inter text-caption-lg text-on-surface-variant;
          @apply text-center;
          @apply whitespace-nowrap;

          @media (min-width: 992px), (max-height: 636px) {
            @apply hidden;
          }

          @media (min-width: 1200px) and (min-height: 637px) {
            @apply block;
          }
        }
      }
    }

    .artists-list > :first-child {
      @apply ml-16px;
    }
  }

  .buttons {
    @apply w-100%;
    @apply bg-surface+2;
    @apply flex;
    @apply gap-8px;
    @apply py-8px px-16px;
    @apply border-t border-surface-variant;
    @apply z-40;

    .bottom-info {
      @apply flex items-center;
      @apply gap-1px;
      @apply border-2 border-primary;
      @apply rounded-8px;
      @apply px-13px;
      @apply font-inter font-medium text-button-lg text-primary;

      svg {
        @apply -mx-3px;
        @apply fill-primary scale-75;
      }
    }
  }

  .customTransition {
    transition: width 200ms ease-in-out;
    .state-layer {
      transition: background-color 200ms ease-in-out;
    }
  }

  .loading-container {
    @apply flex items-center justify-center h-full;
  }
}
